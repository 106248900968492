/* eslint-disable valid-jsdoc */
import * as moment from "moment";

/**
 * getScreenWidth
 * @return {any} returns screen width
 */
export function getScreenWidth() {
  if (typeof window !== `undefined`) {
    return window.innerWidth;
  }
}

/**
 * getScreenWidth
 * @return {any} returns screen width
 */
export function getScreenHeight() {
  if (typeof window !== `undefined`) {
    return window.innerHeight;
  }
}

/**
 * getScreenWidth
 * @return {any} returns screen width
 */
export function isWideScreen() {
  if (typeof window !== `undefined`) {
    const windowWidth = window.innerWidth;
    const mediaQueryL = 1024;

    return windowWidth >= mediaQueryL;
  }
}

/**
 * getScreenWidth
 * @param {number} timeouts
 * @param {string} name
 * @param {number} delay
 * @param {function} handler
 * @return {function} returns screen width
 */
export function timeoutThrottlerHandler(timeouts, name, delay, handler) {
  if (!timeouts[name]) {
    timeouts[name] = setTimeout(() => {
      timeouts[name] = null;
      handler();
    }, delay);
  }
}

/**
 * getScreenWidth
 * @param {string} start
 * @param {string} end
 * @return {string} returns formatted date
 */
export function formatDate(start, end) {
  let formattedEventDate = "";

  const startDate = moment(start, moment.ISO_8601);
  const endDate = moment(end, moment.ISO_8601);

  if (end == null) {
    // 1953-02-01T00:00:00+00:00
    formattedEventDate = startDate.format("D MMM, YYYY");
  } else {
    if (
      startDate.format("D MMM").toUpperCase() == "1 JAN" &&
      endDate.format("D MMM").toUpperCase() == "31 DEC" &&
      startDate.format("YYYY") == endDate.format("YYYY")
    ) {
      formattedEventDate = "In the year " + startDate.format("YYYY");
    } else if (
      startDate.format("D MMM").toUpperCase() == "1 JAN" &&
      endDate.format("D MMM").toUpperCase() == "1 JAN" &&
      startDate.format("YYYY") != endDate.format("YYYY")
    ) {
      formattedEventDate =
        "Between the years  " + startDate.format("YYYY") + " and " + endDate.format("YYYY");
    } else if (
      startDate.format("D").toUpperCase() == "1" &&
      endDate.format("D") >= 28 &&
      startDate.format("MM") != endDate.format("MM") &&
      startDate.format("YYYY") != endDate.format("YYYY")
    ) {
      formattedEventDate =
        "Between " +
        startDate.format("MMMM [of] YYYY") +
        " and " +
        endDate.format("MMMM [of] YYYY");
    } else if (
      startDate.format("D").toUpperCase() == "1" &&
      endDate.format("D") >= 28 &&
      startDate.format("MM") != endDate.format("MM")
    ) {
      formattedEventDate =
        "Between the months of  " +
        startDate.format("MMM") +
        " and " +
        endDate.format("MMM") +
        " " +
        startDate.format("YYYY");
    } else if (
      startDate.format("D").toUpperCase() == "1" &&
      endDate.format("D") >= 28 &&
      startDate.format("MM") == endDate.format("MM") &&
      startDate.format("YYYY") == endDate.format("YYYY")
    ) {
      formattedEventDate = "In " + startDate.format("MMMM [of] YYYY");
    } else if (start == end) {
      formattedEventDate = startDate.format("D MMM, YYYY");
    } else {
      formattedEventDate = startDate.format("D MMM, YYYY") + " - " + endDate.format("D MMM, YYYY");
    }
  }
  return formattedEventDate;
}
