
import styled, { keyframes } from "styled-components";
import { device } from '../../theme/device'; 

export const Footer = styled.footer`
  background: ${(props) => props.theme.color.neutral.white};
  padding: ${(props) => props.theme.space.inset.default};
  padding-top: 0;
  padding-bottom: 120px;

  ul {
    list-style: none;
    text-align: center;
    padding: 0;
  }

  li {
    color: ${(props) => props.theme.color.brand.primaryActive};
    font-size: ${(props) => props.theme.font.size.xxs};
    font-weight: bold;
    padding: ${(props) => props.theme.space.xxs} ${(props) => props.theme.space.s};
    position: relative;
    display: inline-block;

    &::after {
      content: "•";
      position: absolute;
      right: ${`calc(${(props) => props.theme.space.xs} * -1)`};
    }
    &:last-child::after {
      content: "";
    }
  }

  @media ${device.laptop} {
    padding: 0 1em 1.5em;
  }
`;
