/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import { Link } from "gatsby";
import PropTypes from "prop-types";
// import React, { useState, useEffect, useRef } from "react";
import React, { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { ScreenWidthContext, FontLoadedContext } from "../../layouts";
import config from "../../../content/meta/config";
import Menu from "../Menu";

import avatar from "../../images/png/logo_zantana_x192.png";
import { getScreenWidth } from "../../utils/helpers";
import { AiOutlineSearch } from "react-icons/ai/";

import * as styled from "./Header.styled";
import { ThemeProvider } from "styled-components";


const Header = (props) => {
  // state = {
  //   fixed: false,
  // };

  // const isFixed = useRef([]);
  const [fixed, setFixed] = useState("");

  const searchButton = useRef(null);

  // useEffect(() => {
  //   // you know what is this, don't you?
  // }, []);

  const visibilitySensorChange = (val) => {

    if (val) {
      setFixed("");
    } else {
      setFixed("fixed");
    }

    // if (val) {
    //   this.setState({ fixed: false });
    // } else {
    //   this.setState({ fixed: true });
    // }
  };

  const getHeaderClass = () => {
    // const fixed = fixed ? "fixed" : "";
    const homepage = props.path == "/" && fixed == "" ? "homepage" : "";
    // const homepage = this.props.path == "/" ? "fixed" : "";



    return `header ${fixed} ${homepage}`;
  };

  // render() {
  const { pages, path, theme } = props;
  // const { fixed } = this.state;

  return (
    <React.Fragment>
      <ThemeProvider theme={props.theme}>
        <VisibilitySensor onChange={(val) => visibilitySensorChange(val)}>
          <styled.VisibilitySensor className="sensor" path={path} />
        </VisibilitySensor>
        <styled.Header className={`${getHeaderClass()}`}>
          <div className="header-bar-items">
            <Link to="/" className="logoType">
              <styled.Logo className={`logo ${getHeaderClass()}`}>
                <img
                  src={config.gravatarImgMd5 == "" ? avatar : config.gravatarImgMd5}
                  alt={config.siteTitle}
                />
              </styled.Logo>
            </Link>
            <styled.SearchButton className="search-button" ref={searchButton}>
              <Link to="/search/" >
                <AiOutlineSearch
                  className="feature-icon-svg"
                  fill={theme.color.brand.primaryActive}
                  strokeWidth="0"
                  size={getScreenWidth() ? Math.max(getScreenWidth() * 0.03, 32) : 32}
                  title="Search"
                />
              </Link>
            </styled.SearchButton>
          </div>
        </styled.Header>
      </ThemeProvider>
    </React.Fragment>
  );
};
// }

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
};

export default Header;
