module.exports = {
  siteTitle: "Zantana - Narrating History of Eritrea through Personal Stories", // <title>
  shortSiteTitle: "Zantana Stories", // <title> ending for posts and pages
  siteDescription:
    "Eritrean stories narrated by inidividuals sharing their personal experiences within the context of the major events that shaped the history of Eritrea",
  siteUrl: "https://zantana.net",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",
  /* author */
  authorName: "Azentawi",
  authorTwitterAccount: "zantana",

  /* info */
  headerTitle: "",
  headerSubTitle: "",

  /* manifest.json */
  manifestName: "Zantana - Narrating History of Eritrea through Personal Stories",
  manifestShortName: "Zantana", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "#986d29",
  manifestThemeColor: "#986d29",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: {
    twitter: "https://twitter.com/zantanastories",
    instagram: "https://www.instagram.com/zantanastories/",
    twitterSite: "@zantanastories",
    twitterCreator: "@zantanastories",
  },
};
