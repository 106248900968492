import styled, { keyframes } from "styled-components";
import { device } from '../../theme/device'; 



export const VisibilitySensor = styled.div`
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0;
    right: 0;
    height: 5px;

    @media ${device.mobileS} {
        top: ${(props) => { return props.path == "/" ? props.theme.header.height.homepage : props.theme.header.height.fixed; }};
    }

    @media ${device.tablet} {
        top: ${(props) => { return props.path == "/" ? props.theme.header.height.homepage : props.theme.header.height.default; }};
    }

    @media ${device.laptop} {
        top: ${(props) => { return props.path == "/" ? props.theme.header.height.homepage : props.theme.header.height.default; }};
    }
`;


export const Header = styled.div`
  .header-bar-items {
    position: fixed;
    top: 0;
    width: 100%;
  }

  &.fixed {
    position: relative;
    top: 0px;
    background-color: white;

    .header-bar-items {
      transition: all 0.5s;
      background-color: ${(props) => props.theme.color.neutral.white};
      border-bottom: ${(props) => props.theme.color.brand.primary} solid
        ${(props) => props.theme.space.xxs};
      padding-bottom: ${(props) => props.theme.space.xs};
    }

    img {
      filter: invert(0%);
    }
  }

  &.homepage {
    position: fixed;
    background-color: transparent;
    height: ${(props) => props.theme.header.height.homepage};
  }

  z-index: 5;
  align-items: center;
  justify-content: center;
  display: flex;

  width: 100%;
  align-items: center;

  a {
    align-items: center;
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.text.color.primary};
  }

  @media ${device.mobileS} {
    // padding: ${(props) => props.theme.space.inset.s};

    &.homepage:not(.fixed) {
      a {
        color: ${(props) => props.theme.color.neutral.white};
      }
    }

    &.homepage {
      height: ${(props) => props.theme.header.height.homepage};
    }
  }

  @media ${device.tablet} {
    // padding: ${(props) => props.theme.space.inset.l};

    &.homepage:not(.fixed) {
      a {
        color: ${(props) => props.theme.color.neutral.white};
      }
    }

    &.homepage {
      height: ${(props) => props.theme.header.height.homepage};
    }
  }

  @media ${device.laptop} {
    // padding: ${(props) => props.theme.space.inset.l};

    &.homepage:not(.fixed) {
      a {
        color: ${(props) => props.theme.color.neutral.white};
      }
    }

    &.homepage {
      height: ${(props) => props.theme.header.height.homepage};
    }
  }
`;

export const Logo = styled.div`
    flex-shrink: 0;
    display: inline-block;
    height: ${props => props.theme.font.size.superLarge};
    width: ${props => props.theme.font.size.superLarge};

    overflow: hidden;
    transition: all 0.5s;

    &.fixed {
        // background-color: transparent;
        img {
          filter: invert(0%);
        }
    }

    &.homepage {
      height: 100px;
      width: 100px;
    }

    :not(.homepage) {
        background-color: ${props => props.theme.color.brand.darkActive};
    }

    img {
        width: 100%;
    }

    @media ${device.mobileS} {
        border: none;
        margin-top: ${props => props.theme.space.xs};
        height: calc(${props => props.theme.header.height.homepage} * .5);
        width: calc(${props => props.theme.header.height.homepage} * .5);
    }

    @media ${device.tablet} {
        border: none;
        margin-top: ${props => props.theme.space.xs};
        height: calc(${props => props.theme.header.height.homepage} * .5);
        width: calc(${props => props.theme.header.height.homepage} * .5);
    }

    @media ${device.laptop} {
        margin: ${props => props.theme.space.inline.default};

        &.fixed {
          height: 3.5em;
          width: 3.5em;
        }

        :not(.fixed) {
          border: none;
        }        
    }

    @media print {
        &.menu,
        &.fixed {
          header: none;
        }
    }


`;


export const SearchButton = styled.div`
    opacity: 1;
    border: solid ${props => props.theme.color.brand.primaryActive} 3px;
    border-radius: ${props => props.theme.size.radius.default};
    position: absolute;
    top:  calc(${props => props.theme.space.m} * .4);
    right: calc(${props => props.theme.space.m} * .4);

    svg {
        fill: ${props => props.theme.color.brand.primaryActive};
        stroke: ${props => props.theme.color.brand.primaryActive};
        height: 40px;
        width: 40px;
    }
`;


export const LogoLink = styled.a`
    align-items: center;
    display: flex;
    flex-direction: "column";
    color: ${props => props.theme.text.color.primary};

    @media ${device.mobileS} {
        color: ${props => props.theme.color.neutral.white};
    }

    @media ${device.tablet} {
        color: ${props => props.theme.color.neutral.white};
    }

    @media ${device.laptop} {
        color: ${props => props.theme.color.neutral.white};
    }


`;

