import React from "react";
import PropTypes from "prop-types";

import * as styled from "./Footer.styled";
import { ThemeProvider } from "styled-components"; 



const Footer = props => {
  const { html, theme } = props;


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <styled.Footer className="footer" dangerouslySetInnerHTML={{ __html: html }} />
      </ThemeProvider>
      
    </React.Fragment>
  );
};

Footer.propTypes = {
  html: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default Footer;
