/* eslint-disable no-invalid-this */
/* eslint-disable require-jsdoc */
import "typeface-open-sans";
import FontFaceObserver from "fontfaceobserver";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { graphql, StaticQuery, Link } from "gatsby";
import themeObjectFromYaml from "../theme/theme.yaml";
import classNames from "classnames";
import BurgerMenu from "react-burger-menu";

import { getScreenWidth, timeoutThrottlerHandler } from "../utils/helpers";
import Footer from "../components/Footer/";
import Header from "../components/Header";

import { FaHome } from "react-icons/fa/";
import { FaSearch } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa/";
import { FaTags } from "react-icons/fa/";
import { FaReadme } from "react-icons/fa/";
import { FiBook } from "react-icons/fi";
import { GiBookshelf } from "react-icons/gi";
import { FaHistory } from "react-icons/fa/";

import { AiOutlineHistory } from "react-icons/ai/";
import { AiOutlineUser } from "react-icons/ai/";
import { RiUserLine } from "react-icons/ri/";
import { RiMapPinLine } from "react-icons/ri/";
import { GiNewspaper } from "react-icons/gi/";
import { FiMail } from "react-icons/fi/";
import { FiInfo } from "react-icons/fi/";

import ReactModal from 'react-modal';


export const ThemeContext = React.createContext(null);
export const ScreenWidthContext = React.createContext(0);
export const FontLoadedContext = React.createContext(false);


class MenuWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
    };
  }




  UNSAFE_componentWillReceiveProps(nextProps) {
    const sideChanged = this.props.children.props.right !== nextProps.children.props.right;

    if (sideChanged) {
      this.setState({ hidden: true });

      setTimeout(() => {
        this.show();
      }, this.props.wait);
    }
  }

  show() {
    this.setState({ hidden: false });
  }


  render() {
    let style;

    if (this.state.hidden) {
      style = { display: "none" };
    }

    return (
      <div style={style} className={this.props.side}>
        {this.props.children}
      </div>
    );
  }
}


// ReactModal.setAppElement('main');



class Layout extends React.Component {
  constructor() {
    super();

    this.state = {
      font400loaded: false,
      font600loaded: false,
      screenWidth: 0,
      headerMinimized: false,
      theme: themeObjectFromYaml,
      currentMenu: "slide",
      side: "right",
      isModalOpen: false,
    };

    if (typeof window !== `undefined`) {
      console.log('window')
      this.loadFont("font400", "Cutive Mono", 400);
      this.loadFont("font600", "Cutive Mono", 600);
    }
  }

  timeouts = {};

  componentDidMount() {
    this.setState({
      screenWidth: getScreenWidth(),
    });
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resizeThrottler, false);
    }
  }

  resizeThrottler = () => {
    return timeoutThrottlerHandler(this.timeouts, "resize", 100, this.resizeHandler);
  };

  resizeHandler = () => {
    this.setState({ screenWidth: getScreenWidth() });
  };

  isHomePage = () => {
    if (this.props.location.pathname === "/") {
      return true;
    }

    return false;
  };

  loadFont = (name, family, weight) => {
    const font = new FontFaceObserver(family, {
      weight: weight,
    });

    font.load(null, 10000).then(
      () => {

        this.setState({ [`${name}loaded`]: true });
      },
      () => {

      }
    );
  };

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  getItems() {
    let items;

    // switch (this.props.menus[this.state.currentMenu].items) {
    switch (1) {
      case 1:
        items = [
          <Link key={0} to={"/"} onClick={() => this.closeMenu()}>
            {<FaHome className="menu-icon" />}
            <span>Home</span>
          </Link>,
          <hr key="111" className="menu-separator" />,
          <Link key={10} to={"/stories/"} onClick={() => this.closeMenu()}>
            {<FaReadme className="menu-icon" />}
            <span>Stories</span>
          </Link>,

          <Link key={20} to={"/category/"} onClick={() => this.closeMenu()}>
            {<FaTags className="menu-icon" />}
            <span>Categories</span>
          </Link>,
          <Link key={30} to={"/search/"} onClick={() => this.closeMenu()}>
            {<FaSearch className="menu-icon" />}
            <span>Search</span>
          </Link>,
          <hr key="111" className="menu-separator" />,

          <Link key={40} to={"/timespan/"} onClick={() => this.closeMenu()}>
            {<AiOutlineHistory className="menu-icon" />}
            <span>The Timeline</span>
          </Link>,
          <Link key={50} to={"/context/"} onClick={() => this.closeMenu()}>
            {<GiNewspaper className="menu-icon" />}
            <span>The Context</span>
          </Link>,
          <Link key={60} to={"/places/"} onClick={() => this.closeMenu()}>
            {<RiMapPinLine className="menu-icon" />}
            <span>The Places</span>
          </Link>,
          <Link key={70} to={"/books/"} onClick={() => this.closeMenu()}>
            {<GiBookshelf className="menu-icon" />}
            <span>Books</span>
          </Link>,
          <hr key="111" className="menu-separator" />,

          <Link key={90} to={"/about/"} onClick={() => this.closeMenu()}>
            {<FiInfo className="menu-icon" />}
            <span>About</span>
          </Link>,
          <Link key={90} to={"/privacy-policy/"} onClick={() => this.closeMenu()}>
            {<FiInfo className="menu-icon" />}
            <span>Privacy Policy</span>
          </Link>,
          <Link key={100} to={"/contact/"} onClick={() => this.closeMenu()}>
            {<FiMail className="menu-icon" />}
            <span>Contact</span>
          </Link>,
        ];
        break;
    }

    return items;
  }

  getMenu() {
    const Menu = BurgerMenu[this.state.currentMenu];

    return (
      <MenuWrap wait={20} theme={this.state.theme}>
        <Menu
          id={this.state.currentMenu}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          pageWrapId={"___gatsby"}
          outerContainerId={"gatsby-focus-wrapper"}
          right={this.state.side === "left"}
          crossButtonClassName={"close-button"}
          burgerButtonClassName={"open-button"}
          
        >
          {this.getItems()}
        </Menu>
      </MenuWrap>
    );
  }


  
  handleModalOpen = event => {

    this.setState({ isModalOpen: true });
    // handleModal(true);
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false });
    // handleModal(false);
  }

  bg = {
    overlay: {
      backgroundColor: "#986d2982"
    }
  };



  render() {
    // const menus = {
    //   slide: { buttonText: "Slide", items: 1 },
    //   stack: { buttonText: "Stack", items: 1 },
    //   elastic: { buttonText: "Elastic", items: 1 },
    //   bubble: { buttonText: "Bubble", items: 1 },
    //   push: { buttonText: "Push", items: 1 },
    //   pushRotate: { buttonText: "Push Rotate", items: 2 },
    //   scaleDown: { buttonText: "Scale Down", items: 2 },
    //   scaleRotate: { buttonText: "Scale Rotate", items: 2 },
    //   fallDown: { buttonText: "Fall Down", items: 2 },
    //   reveal: { buttonText: "Reveal", items: 1 },
    // };

    // const buttons = Object.keys(menus).map((menu) => {
    //   return (
    //     <a
    //       key={menu}
    //       className={classNames({ "current-demo": menu === this.state.currentMenu })}
    //       onClick={this.changeMenu.bind(this, menu)}
    //     >
    //       {this.props.menus[menu].buttonText}
    //     </a>
    //   );
    // });


    return (
      <StaticQuery
        query={graphql`
          query LayoutgQuery {
            pages: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//pages//" }, frontmatter: {audience: { eq : "public"}}, fields: { prefix: { regex: "/^\\d+$/" } } }
              sort: { fields: {prefix: DESC } }
            ) {
              edges {
                node {
                  fields {
                    slug
                    prefix
                  }
                  frontmatter {
                    title
                    menuTitle
                  }
                }
              }
            }
            footnote: markdownRemark(fileAbsolutePath: { regex: "/footnote/" }) {
              id
              html
            }
          }
        `}
        render={(data) => {
          const { children } = this.props;
          const {
            footnote: { html: footnoteHTML },
            pages: { edges: pages },
          } = data;

          return (
            <ThemeContext.Provider value={this.state.theme}>
              <FontLoadedContext.Provider value={this.state.font400loaded}>
                <ScreenWidthContext.Provider value={this.state.screenWidth}>
                  <React.Fragment>
                    {this.getMenu()}
                    <Header
                      path={this.props.location.pathname}
                      pages={pages}
                      theme={this.state.theme}
                    />
                    <main>{children}</main>
                    <Footer html={footnoteHTML} theme={this.state.theme} />

                    {/* <ReactModal
                      isOpen={this.state.isModalOpen}
                      onRequestClose={this.handleModalClose}
                      contentLabel="Example Modal In Gatsby">
                      <h2>{place}</h2>
                      <Link  to="#" onClick={this.handleModalClose}><FaWindowClose /></Link> */}
                    {/* <GoogleMapComponent
                              isMarkerShown
                              lat={coordinates.coordinates[1]}
                              long={coordinates.coordinates[0]} 
                              googleMapsAPIKey={googleMaps.googleMapsAPIKey}
                            /> */}
                    {/* </ReactModal> */}

                    <style>{`

                      main {
                        min-height: 80vh;
                      }


                      html {
                        box-sizing: border-box;
                      }
                      *,
                      *:after,
                      *:before {
                        box-sizing: inherit;
                        margin: 0;
                        padding: 0;
                      }
                      body {
                        font-family: ${
                          this.state.font400loaded
                            ? "'Cutive Mono', 'Open Sans', sans-serif;"
                            : "Arial, sans-serif;"
                        };
                      }
                      h1,
                      h2,
                      h3 {
                        font-weight: ${this.state.font600loaded ? 600 : 600};
                        line-height: 1.1;
                        letter-spacing: -0.03em;
                        margin: 0;
                      }
                      h1 {
                        letter-spacing: -0.04em;
                        font-size: 5rem;
                        line-height: 1;
                      }
                      p {
                        margin: 0;
                        font-weight: bold;
                      }
                      strong {
                        font-weight: ${this.state.font600loaded ? 400 : 600};
                      }
                      a {
                        text-decoration: none;
                        color: #666;
                      }
                      main {
                        width: auto;
                        display: block;
                      }

                      .bm-burger-button {
                        position: fixed;
                        left: 36px;
                        top: 10px;
                      }

                      .bm-menu-wrap {
                        background-color: ${this.state.theme.color.brand.primaryActive};
                        padding: ${this.state.theme.space.l} ${this.state.theme.space.m};
                      }
                      
                      .bm-item {
                        color: ${this.state.theme.color.neutral.gray.c};
                        font-size: ${this.state.theme.font.size.m};
                        line-height: ${this.state.theme.font.lineHeight.xl};
                        vertical-align: bottom;
                      }

                      .menu-separator {
                        position: relative;
                        border: none;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.1);
                        margin-bottom: ${this.state.theme.space.s};
                        margin-top: ${this.state.theme.space.s};
                      }

                      .close-button {
                        /* */
                      }

                      .bm-cross {
                        background-color: ${this.state.theme.color.brand.darkActive};
                      }

                      .bm-cross:hover {
                        background-color: ${this.state.theme.color.brand.orange};
                      }
                        

                      .menu-icon {
                        font-size: ${this.state.theme.font.size.xxs};
                        margin-right: ${this.state.theme.font.size.xxs};
                      }
                      

                      .open-button,
                      .bm-burger-button {
                        width: 36px;
                        height: 36px;
                        left: 18px;
                      }

                      .bm-burger-bars {
                        position: relative;
                        width: 100%;
                        background-color: ${this.state.theme.color.brand.primaryActive};
                      }

                      .ReactModal__Overlay--after-open {
                        z-index: 1000;
                        background-color: #986d2982  !important;
                      }

                      
                    `}</style>
                  </React.Fragment>
                </ScreenWidthContext.Provider>
              </FontLoadedContext.Provider>
            </ThemeContext.Provider>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // menus: PropTypes.object.isRequired,
};

MenuWrap.propTypes = {
  children: PropTypes.object.isRequired,
  wait: PropTypes.number.isRequired,
  side: PropTypes.string,
  theme: PropTypes.object,
};

export default Layout;
